import React, {useCallback} from 'react';
import {
  FormLayout,
  Checkbox,
  TextStyle,
  TextField,
} from '@shopify/polaris';
import update from "immutability-helper";

const AdvancedTab = (props) => {
  const {state, setState} = props;

  const handleChange = useCallback(
    (newChecked) => setState(update(state, {swapPrice: {$set: newChecked}})),
    [],
  );
  const handlePricingDecimals = useCallback(
    (value) => setState(update(state, {pricingDecimals: {$set: value}})),
    [],
  );
  return (
    <FormLayout>
      <TextStyle>Change which amount is crossed out:</TextStyle>
      <Checkbox
        label="Swap original price and compare at price"
        checked={state.swapPrice}
        onChange={handleChange}
      />
      <TextField
        label="Pricing decimals"
        min={0}
        value={state.pricingDecimals}
        onChange={(value) => handlePricingDecimals(value)}
        autoComplete="off"
        placeholder="2"
        type="number"
      />
    </FormLayout>
  );
};

export default AdvancedTab;